<script>

import BaseInput from "~/components/Base/BaseInput"
import BaseButton from "~/components/Base/BaseButton"
import ConnectButton from "./ConnectButton"
import { reactive, toRefs } from "vue";
import BasePageContentWrapper from "~/components/Base/BasePageContentWrapper";
import useVuelidate from '@vuelidate/core'
import { required, email as emailValidation } from '@vuelidate/validators'
import { login } from "~/services/api/auth.api"
import { redirectTo } from "~/utils/genericUtils";
import { fieldInvalid, getFieldError } from "~/utils/fieldUtils";
import RegisterModal from "~/components/Login/RegisterModal";
import PopupComponent from "~/components/Shared/PopupComponent";
import { useStore } from "vuex";
import { useToast } from "vue-toastification";

export default {
  name: 'login-page',

  components: {
    PopupComponent,
    RegisterModal,
    BasePageContentWrapper,
    BaseInput,
    BaseButton,
    ConnectButton
  },

  setup() {
    const state = reactive({
      email: '',
      password: '',
      loginLoading: false,
      pageLoading: true,
      submitting: false,
      showRegister: false,
      requestError: {}
    })

    const store = useStore()
    const toast = useToast()

    const formValidator = useVuelidate({
        email: { required, emailValidation, $autoDirty: false },
        password: { required, $autoDirty: false }
      }, state)

    async function submit() {
      state.submitting = true
      formValidator.value.$touch()

      const formValid = !formValidator.value.$invalid

      if (!formValid) {
        state.submitting = false
        return
      }

      state.loginLoading = true
      const { error: loginError } = await login(state.email, state.password);

      if (loginError) {
        state.loginLoading = false
        state.requestError = loginError
        state.submitting = false
        return
      }

      await store.dispatch('getProfile')
      redirectTo("/")
    }

    function handleRegisterSuccess() {
      state.showRegister = false
      toast.success("Account successfully created!")
    }

    setTimeout(() => {
      state.pageLoading = false
    }, 10)

    return {
      ...toRefs(state),
      formValidator,
      submit,
      handleRegisterSuccess,
      getFieldError,
      fieldInvalid
    }
  }
}
</script>

<template>
  <base-page-content-wrapper :page-loading="pageLoading">
    <div class="min-h-100vh d-flex align-items-center justify-content-center">
      <div class="container z-index-1">
        <div class="row justify-content-center">
          <div
            class="col-sm-8 col-md-7 col-lg-5 col-xl-4 col-xxl-3 text-center py-8 py-md-17 min-h-100vh min-h-lg-auto d-grid d-lg-block grid-row-column-33">
            <img src="/img/logo_w.svg" class="h-auto w-23 mb-lg-17 d-block mx-auto align-self-center">

            <div class="text-left align-self-start align-self-md-center">
              <base-input
                id="email"
                input-class="form-control form-control-transparent form-control-xl"
                v-model="email"
                placeholder="Email"
                required
                :invalid="fieldInvalid('email', formValidator, requestError, submitting)"
                :error-message="getFieldError('email', formValidator, requestError)"
                @keyup.enter="submit"
              />
              <div class="form-group p-relative">
                <base-input
                  id="password"
                  input-class="form-control form-control-transparent form-control-xl"
                  type="password"
                  v-model="password"
                  placeholder="Password"
                  required
                  :invalid="fieldInvalid('password', formValidator, requestError, submitting)"
                  :error-message="getFieldError('password', formValidator, requestError)"
                  @keyup.enter="submit"
                />
                <base-button
                  type="button"
                  class="btn-linklike-primary border-0 bg-transp font-14 pa-0 p-absolute r-6 t-7 fw-400"
                  value="Forgot Password?"
                />
              </div>
              <base-button
                type="primary"
                value="Sign In"
                class="btn-primary btn-def py-6 px-12 font-15 border-radius d-flex align-items-center justify-content-center mb-6 w-100 btn-shadow"
                :loading="loginLoading"
                @click="submit"
                x-data="1"
              />
            </div>
            <div class="text-left align-self-end">
              <connect-button
                type="primary"
                value="Connect with"
                class="btn-white btn-def py-6 px-12 font-15 border-radius d-flex align-items-center justify-content-center w-100 mb-6 btn-shadow"
                icon="/img/google_logo.svg"
                img-classes="w-auto max-h-9 max-w-21 ml-4"
              />
              <connect-button
                type="primary"
                value="Connect with"
                class="btn-white btn-def py-6 px-12 font-15 border-radius d-flex align-items-center justify-content-center w-100 mb-6 btn-shadow"
                icon="/img/strava_logo.svg"
                img-classes="w-auto max-h-9 max-w-21 ml-4"
              />
              <connect-button
                type="primary"
                value="Connect with"
                class="btn-white btn-def py-6 px-12 font-15 border-radius d-flex align-items-center justify-content-center w-100 mb-6 btn-shadow"
                icon="/img/facebook_logo.svg"
                img-classes="w-auto max-h-9 max-w-21 ml-4 mt--3"
              />
              <div class="d-flex align-items-center justify-content-center mt-12">
                <span class="c-primary font-14">Don't have an account?</span>
                <base-button
                  class="btn-linklike-primary border-0 bg-transp font-14 pa-0 ml-4"
                  type="button"
                  value="Sign Up Now!"
                  @click="showRegister = true"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <popup-component v-if="showRegister" v-model="showRegister" >
      <register-modal @click.stop @register-success="handleRegisterSuccess"/>
    </popup-component>
  </base-page-content-wrapper>

</template>


