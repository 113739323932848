import { createApp } from 'vue'
import LoginPage from '~/components/Login/LoginPage.vue'
import store from "~/store"
// import VueFinalModal from 'vue-final-modal'
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";

createApp(LoginPage)
  .use(store)
  .use(Toast, {
    hideProgressBar: true,
    timeout: 5000
  })
  .mount('#app')
