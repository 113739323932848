<script>
import CardWrapper from "~/components/Shared/CardWrapper";
import CardHeader from "~/components/Shared/CardHeader";
import CardBody from "~/components/Shared/CardBody";
import BaseInput from "~/components/Base/BaseInput";
import BaseButton from "~/components/Base/BaseButton";
import  { reactive, toRefs, ref } from "vue"
import useVuelidate from '@vuelidate/core'
import { required, email as emailValidation, sameAs } from '@vuelidate/validators'
import { fieldInvalid, getFieldError } from "~/utils/fieldUtils";
import { register } from "~/services/api/auth.api";
import { useToast } from "vue-toastification";

export default {
  name: "register-modal",

  components: {
    BaseButton,
    BaseInput,
    CardBody,
    CardHeader,
    CardWrapper
  },

  setup(props, { emit }) {
    const userState = reactive({
      firstName: '',
      lastName: '',
      email: '',
      confirmPassword: '',
    })



    // Need this as ref for vuelidate to work.
    const password = ref('')
    const toast = useToast()

    const internalState = reactive({
      registerLoading: false,
      submitting: false,
      requestError: {}
    })

    const formValidator = useVuelidate({
      firstName: { required },
      lastName: { required },
      email: { required, emailValidation, $autoDirty: false },
      password: { required, $autoDirty: false },
      confirmPassword: { required, sameAs: sameAs( password, 'password') }
    }, {...toRefs(userState), password})

    async function submit() {
      internalState.submitting = true
      internalState.registerLoading = true
      formValidator.value.$touch()

      const formValid = !formValidator.value.$invalid

      if (!formValid) {
        internalState.submitting = false
        internalState.registerLoading = false
        return
      }

      const userData = {
        ...userState,
        password: password.value,
        password_confirmation: userState.confirmPassword
      }

      const { error } = await register(userData)

      if (error) {
        internalState.requestError = error
        internalState.registerLoading = false
        internalState.submitting = false

        if (error.password_confirmation) {
          internalState.requestError['confirmPassword'] = error.password_confirmation
        }

        if (typeof error === 'string') {
          toast.error(error, {
            timeout: false
          })
        }
        return
      }

      emit('register-success')
    }

    return {
      ...toRefs(userState),
      ...toRefs(internalState),
      password,
      formValidator,
      fieldInvalid,
      getFieldError,
      submit
    }
  }
}
</script>

<template>
    <card-wrapper class="mb-12 col-xs-12 col-md-5">
      <card-header
        card-title="Create New Account"
        class="bg-wh border-radius-top-xl c-cloud-burst font-18 font-xl-16 font-xxl-18 fw-600 pb-3 pt-12 text-center"
      />
      <card-body class="bg-wh border-radius-bottom-xl d-flex pa-6 py-8 flex-wrap">
        <div class="col-12">
          <base-input
            id="emailRegister"
            input-class="form-control"
            placeholder="Your email address..."
            label-text="Email"
            v-model="email"
            :invalid="fieldInvalid('email', formValidator, requestError, submitting)"
            :error-message="getFieldError('email', formValidator, requestError)"
            required
          />
        </div>
        <div class="col-12 col-lg-6">
          <base-input
            id="firstNameRegister"
            input-class="form-control"
            placeholder="Your first name..."
            label-text="First Name"
            v-model="firstName"
            :invalid="fieldInvalid('firstName', formValidator, requestError, submitting)"
            :error-message="getFieldError('firstName', formValidator, requestError)"
            required
          />
        </div>
        <div class="col-12 col-lg-6">
          <base-input
            id="lastNameRegister"
            input-class="form-control"
            placeholder="Your last name..."
            label-text="Last Name"
            v-model="lastName"
            :invalid="fieldInvalid('lastName', formValidator, requestError, submitting)"
            :error-message="getFieldError('lastName', formValidator, requestError)"
            required
          />
        </div>
        <div class="col-12 col-lg-6">
          <base-input
            id="passwordRegister"
            input-class="form-control"
            type="password"
            placeholder="Your password..."
            label-text="Password"
            v-model="password"
            :invalid="fieldInvalid('password', formValidator, requestError, submitting)"
            :error-message="getFieldError('password', formValidator, requestError)"
            required
          />
        </div>
        <div class="col-12 col-lg-6">
          <base-input
            id="confirmPasswordRegister"
            input-class="form-control"
            type="password"
            placeholder="Confirm password..."
            label-text="Confirm Password"
            v-model="confirmPassword"
            :invalid="fieldInvalid('confirmPassword', formValidator, requestError, submitting)"
            :error-message="getFieldError('confirmPassword', formValidator, requestError)"
            required
          />
        </div>
        <div class="col-12">
          <base-button
            type="primary"
            value="Sign Up"
            class="btn-primary btn-def py-6 px-12 font-15 border-radius d-flex align-items-center justify-content-center mb-6 w-100 btn-shadow"
            x-data="1"
            :loading="registerLoading"
            @click="submit"
          />
        </div>
      </card-body>
    </card-wrapper>
</template>

