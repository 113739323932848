<script>
export default {
  name: 'connect-button',

  props: {
    type: {
      type: String,
      default: 'text'
    },
    value: {
      type: String,
      default: null,
    },
    icon: {
      type: String,
      default: null,
    },
    classes: {
      type: String,
      default: '',
    },
    imgClasses: {
      type: String,
      default: ''
    }
  },
}
</script>

<template>
  <button>
    <slot>
      {{ value }}
      <img :src="icon" :class="[imgClasses]"/>
    </slot>
  </button>
</template>